import React from "react";

import CookieConsentContextProvider from "@finanzchef24gmbh/insurance-product-system/src/components/CookieConsentContextProvider";
import GlobalStateContextProvider from "./src/components/GlobalStateContextProvider";

export function wrapRootElement({ element }) {
  return (
    <CookieConsentContextProvider>
      <GlobalStateContextProvider>{element}</GlobalStateContextProvider>
    </CookieConsentContextProvider>
  );
}

function gtagForUniversalAnalyticsPageView(location, prevLocation) {
  // We set this variable inside a script included in gatsby-ssr.js
  const trackingId = window.GATSBY_GOOGLE_ANALYTICS_TRACKING_ID;

  if (
    !trackingId ||
    typeof window.gtag !== "function" ||
    // Do not track the pageview if the page is loaded initially, the Google
    // Analytics does this by default.
    !prevLocation
  ) {
    return;
  }

  window.gtag("config", trackingId, {
    anonymize_ip: true,
    page_path: location
      ? `${location.pathname}${location.search}${location.hash}`
      : "",
  });
}

function gtagForGA4PageView(location, prevLocation) {
  // We set this variable inside a script included in gatsby-ssr.js
  const trackingId = window.GATSBY_GA4_TRACKING_ID;

  if (
    !trackingId ||
    typeof window.gtag !== "function" ||
    // Do not track the pageview if the page is loaded initially, the Google
    // Analytics does this by default.
    !prevLocation
  ) {
    return;
  }

  window.gtag("config", trackingId, {
    anonymize_ip: true,
    groups: "ga4",
    page_path: location
      ? `${location.pathname}${location.search}${location.hash}`
      : "",
  });
}

export function onRouteUpdate({ location, prevLocation }) {
  // Update page-path for global-site-tag
  gtagForUniversalAnalyticsPageView(location, prevLocation);
  gtagForGA4PageView(location, prevLocation);
}
